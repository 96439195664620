import ScrollMagic from "scrollmagic";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
// import TweenMax from 'gsap/src/uncompressed/TweenMax';
import TimelineMax from 'gsap/src/uncompressed/TimelineMax';
import {Power2, Linear} from 'gsap/all';
import utils from './_utils';

const defaults = {
    selectors: {
        animation: 'data-scroll-animation',
        parallaxElement: 'data-parallax-element'
    },
    classes: {
        directions: {
            up: 'up',
            right: 'right',
            down: 'down',
            left: 'left',
            still: 'still'
        }
    },
    config: {
        reverse: true
    }
};

export default class scrollAnimations {

    constructor(node) {
        const members = defaults;
        this.element = document;
        this.controller = null;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.config = members.config;
    }

    init() {
        this.controller = new ScrollMagic.Controller();

        const that = this;
        const elementAnimation = this.element.querySelectorAll('[' + this.selectors.animation + ']');

        if (elementAnimation.length) {
            elementAnimation.forEach(function (element) {
                let type = element.dataset.scrollAnimation;

                if ((type !== '' || type !== 'undefined') && type !== 'none') {
                    that[type](element);
                }
            });
        }
    }

    /**
     * Refresh scene
     *
     * Destroy the current controller, scene elements
     * and initialize the scene again
     */
    refreshScene() {
        this.destroyControllerAndResetScene();
        this.init();
        // console.log('scroll animation refresh');
    }

    /**
     * Destroy controller and reset scene
     *
     * Destroy the current controller and scene elements
     */
    destroyControllerAndResetScene() {
        // console.log(this.controller);
        this.controller.destroy(true);
    }

    fade(element) {
        const videos = element.querySelectorAll('video');
        let options = {
            triggerHook: 0.85,
            duration: 0,
            direction: this.classes.directions.still
        };
        const elementOptions = utils.getOptions(element.dataset.options);

        if (elementOptions) {
            options = Object.assign({}, options, elementOptions);

            if (elementOptions.durationElementSet) {
                options = Object.assign({}, options, {duration: element.closest(elementOptions.durationElementSet).offsetHeight - 50});
                delete options.durationElementSet;
            }
        }

        element.classList.add('will-fade-' + this.classes.directions[options.direction]);

        new ScrollMagic.Scene({
            triggerElement: element,
            duration: options.duration,
            triggerHook: options.triggerHook,
            ...this.config
        })
            .setClassToggle(element, this.classes.directions[options.direction])
            // .addIndicators()
            .addTo(this.controller)
            .on('enter', function () {
                videos.forEach(function (video) {
                    video.play();
                });
            })
            .on('leave', function () {
                videos.forEach(function (video) {
                    video.pause();
                    video.currentTime = 0;
                });
            });
    }

    parallax(element) {
        const container = element.parentElement;
        const containerHeight = container.offsetHeight;

        let options = {
            triggerHook: 0,
            target: element,
            duration: containerHeight,
            direction: 'vertical',
            speed: 100,
            speedSm: 100,
            distance: 100,
            distanceSm: 100
        };

        const elementOptions = utils.getOptions(element.dataset.options);
        const smallScreen = window.innerWidth < 1025 ? true : false;

        if (elementOptions) {
            options = Object.assign({}, options, elementOptions);

            if (elementOptions.target) {
                options = Object.assign({}, options, {target: element.querySelector(options.target)});
            }
        }

        const tween = new TimelineMax();
        let elemSpeed = options.speed;
        let distance = Math.ceil(options.duration / elemSpeed * options.distance);

        if (smallScreen) {
            elemSpeed = options.speedSm;
            distance = Math.ceil(options.duration / elemSpeed * options.distanceSm);
        }

        if (options.direction === 'horizontal') {
            tween.to(element, 1, {x: distance, ease: Linear.easeNone});
        } else {
            tween.to(element, 1, {y: distance, ease: Linear.easeNone});
        }

        new ScrollMagic.Scene({
            triggerElement: container,
            triggerHook: options.triggerHook,
            duration: options.duration,
            ...this.config
        })
            .setTween(tween)
            // .addIndicators()
            .addTo(this.controller);
    }

    // isMobileDevice() {
    //     return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    // }

    parallaxContainer(element) {
        const that = this;
        const containerHeight = element.offsetHeight;
        const parallaxElements = element.querySelectorAll('.parallax-item');

        const smallScreen = window.innerWidth < 1025 ? true : false;
        // const isMobile = this.isMobileDevice();

        let options = {
            triggerHook: 0,
            duration: containerHeight,
            target: element,
            itemsOption: {
                speed: 1,
                speedSm: 1,
                distance: 1,
                distanceSm: 1,
                direction: 'vertical'
            }
        };

        const elementOptions = utils.getOptions(element.dataset.options);

        if (elementOptions) {
            options = Object.assign({}, options, elementOptions);

            if (elementOptions.target) {
                options = Object.assign({}, options, {target: element.querySelector(options.target)});
            }
        }

        const tween = new TimelineMax();

        parallaxElements.forEach(function (elem, index) {
            const itemOptions = utils.getOptions(elem.dataset.options);

            if (itemOptions) {
                options.itemsOption = Object.assign({}, options.itemsOption, itemOptions);
            }

            const elemHeight = elem.offsetHeight;
            let distance =  options.itemsOption.distance;
            let elemSpeed = options.itemsOption.speed;
            let elemDirection = options.itemsOption.direction;

            if (smallScreen) {
                distance = options.itemsOption.distanceSm;
                elemSpeed = options.itemsOption.speedSm;
            }

            const elemInsideTop = elem.getBoundingClientRect().top - element.getBoundingClientRect().top;
            let calcDistance = (containerHeight - elemInsideTop) * distance;

            if (calcDistance > elemHeight) {
                calcDistance = calcDistance - elemHeight;
            }

            let distanceOption = {y: calcDistance};

            if (elemDirection === 'horizontal') {
                distanceOption = {x: calcDistance};
            }

            if (index === 0) {
                tween.to(elem, 1, {...distanceOption, ease: Linear.easeNone})
            } else {
                tween.to(elem, elemSpeed, {...distanceOption, ease: Linear.easeNone}, `-=1`)
            }
        });

        new ScrollMagic.Scene({triggerElement: element, duration: options.duration, triggerHook: options.triggerHook})
            .setTween(tween)
            // .addIndicators()
            .addTo(this.controller);
    }

    imageReveal(element) {
        let options = {
            triggerHook: 0.85,
            duration: 0
        };
        const elementOptions = utils.getOptions(element.dataset.options);

        if (elementOptions) {
            options = Object.assign({}, options, elementOptions);

            if (elementOptions.durationElementSet) {
                options = Object.assign({}, options, {duration: element.closest(elementOptions.durationElementSet).offsetHeight - 50});
                delete options.durationElementSet;
            }
        }

        element.classList.add('will-image-reveal');

        new ScrollMagic.Scene({
            triggerElement: element,
            duration: options.duration,
            triggerHook: options.triggerHook,
            ...this.config
        })
            .setClassToggle(element, 'image-reveal')
            .addTo(this.controller)
    }

    reveal(element) {
        let options = {
            toX: 0,
            toY: 0,
            classOnFinish: null,
            triggerHook: 0.85,
            duration: .7,
            direction: this.classes.directions.up
        };
        const elementOptions = utils.getOptions(element.dataset.options);

        if (elementOptions) {
            options = Object.assign({}, options, elementOptions);
        }
        const videos = element.querySelectorAll('video');
        const mask = document.createElement('div');
        mask.setAttribute('class', 'mask');

        element.prepend(mask);

        if (options.direction === 'up') {
            options = Object.assign({}, options, {
                toX: 0,
                toY: -100
            });
        }
        if (options.direction === 'right') {
            options = Object.assign({}, options, {
                toX: 100,
                toY: 0
            });
        }
        if (options.direction === 'down') {
            options = Object.assign({}, options, {
                toX: 0,
                toY: 100
            });
        }
        if (options.direction === 'left') {
            options = Object.assign({}, options, {
                toX: -100,
                toY: 0
            });
        }

        const tl = new TimelineMax();
        tl.set(mask, {
            yPercent: 0,
            xPercent: 0
        }).to(mask, options.duration, {
            yPercent: options.toY,
            xPercent: options.toX,
            ease: Power2.easeInOut,
            onComplete: addClassToElement,
            onReverseComplete: removeClassFromElement
        });

        new ScrollMagic.Scene({
            triggerElement: element,
            triggerHook: options.triggerHook,
            ...this.config
        })
            .addTo(this.controller)
            .setTween(tl)
            .on('enter', function () {
                videos.forEach(function (video) {
                    video.play();
                });
            })
            .on('leave', function () {
                videos.forEach(function (video) {
                    video.pause();
                    video.currentTime = 0;
                });
            });

        function addClassToElement() {
            if (options.classOnFinish) {
                element.classList.add(options.classOnFinish);
            }
        }

        function removeClassFromElement() {
            if (options.classOnFinish) {
                element.classList.remove(options.classOnFinish);
            }
        }
    }
}
