import $ from 'jquery';

const defaults = {
    $element: undefined
};

export default class ScrollToTarget {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.options = members.options;
    }

    init() {
        const target = this.$element.attr('href');
        const offset = $('.header').height();
        let scrollTopValue;

        if (target === "#") {
            scrollTopValue = 0;
        } else {
            scrollTopValue = $(target).offset().top;
        }

        this.$element.on('click', function(event) {
            event.preventDefault();
            $('html, body').animate({scrollTop: scrollTopValue - offset}, 'fast');
        });
    }
}
