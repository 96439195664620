import $ from 'jquery';

const defaults = {
    $element: undefined,
    selectors: {
        overlay: '.js-overlay',
        backDrop: '.overlay__backdrop',
        close: '.js-close-overlay',
        scrollWrapper: '.overlay__content',
        overlayContent: '.js-overlay-content'
    },
    classes: {
        hidden: 'hidden',
        body: {
            opened: 'overlay-opened'
        }
    },
    data: {
        target: 'data-target-overlay',
        contentId: 'data-overlay-content-id',
        content: 'data-overlay-content'
    }
};

export default class Overlay {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.data = members.data;
    }

    init() {
        this.bind();
    }

    bind() {
        const self = this;
        this.$element.on('click', this.showOverlay.bind(this));

        $(this.selectors.close).on('click', function(event) {
            event.preventDefault();
            self.hideOverlay();
        });

        $(document).keyup(function(e) {
            if (e.key === "Escape") {
                self.hideOverlay();
            }
        });
    }

    showOverlay(event) {
        event.preventDefault();
        const self = this;
        const overlay = $(event.currentTarget).attr(this.data.target);
        const $overlay = $(overlay);
        const contentId = $(this.$element).attr(this.data.contentId);
        const content = $(document).find('[' + this.data.content + '="' + contentId + '"]').html();

        if (contentId && contentId) {
            $overlay.find(this.selectors.overlayContent).html(content);
        }

        $('body').addClass(this.classes.body.opened);
        $overlay.removeClass(this.classes.hidden);
        $(this.selectors.backDrop).removeClass(this.classes.hidden);
        $overlay.find(self.selectors.scrollWrapper).scrollTop(0);

        $(document).on('click.overlay-opened', this.selectors.backDrop, function(event) {
            if ($(event.target).closest(self.selectors.overlay).length === 0) {
                self.hideOverlay();
            }
        });
    }

    hideOverlay() {
        $('body').removeClass(this.classes.body.opened);
        $(this.selectors.backDrop).addClass(this.classes.hidden);
        $(this.selectors.overlay).addClass(this.classes.hidden);
        $(document).off('click.overlay-opened');
    }
}
