import $ from 'jquery';
import TimelineMax from 'gsap/src/uncompressed/TimelineMax';
import {Circ, Power2} from 'gsap/all';
const bodyScrollLock = require('body-scroll-lock');

const defaults = {
    $element: undefined,
    selectors: {
        overlay: '.navigation-overlay',
        overlayBackground: '.navigation-overlay__background',
        overlayBackdrop: '.navigation-overlay__backdrop',
        overlayNavItems: '.js-navigation-overlay-menu-item-animate',
        trigger: '.js-navigation-overlay-trigger',
        closeTrigger: '.js-overlay-close-trigger',
        icon: '.js-navigation-overlay-trigger-icon'
    },
    classes: {
        hidden: 'hidden',
        overlayOpened: 'navigation-overlay-opened',
        triggerActive: 'active',
        triggerDisabled: 'disabled',
        navigationOverlay: 'js-navigation-overlay'
    },
    overlayState: {
        isOpen: false
    }
};

export default class NavigationOverlay {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.overlayState = members.overlayState;
        this.overlay = null;
        this.overlayBackground = null;
        this.overlayBackdrop = null;
        this.navItems = null;
        this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
        this.enableBodyScroll = bodyScrollLock.enableBodyScroll;
    }

    init() {
        const self = this;
        const target = this.$element.attr('data-js-target');

        if ($(target).length) {
            this.overlay = target;

            this.overlayBackground = $(target).find(this.selectors.overlayBackground);
            this.overlayBackdrop = $(this.selectors.overlayBackdrop);
            this.navItems = $(target).find(this.selectors.overlayNavItems);

            this.overlayToggle()

            $(target).find(this.selectors.closeTrigger).on('click', function (){
                self.closeOverlay();
            });

            $(document).click(function (e) {
                if (self.overlayState.isOpen) {
                    const clicked = e.target;
                    const $clicked = $(clicked);

                    if (!$clicked.is(self.selectors.trigger) && !$clicked.parents().is(target)) {
                        self.closeOverlay();
                    }
                }
            });

            $(document).keyup(function(e) {
                if (self.overlayState.isOpen) {
                    if (e.key === "Escape") {
                        self.closeOverlay();
                    }
                }
            });
        }
    }

    overlayToggle() {
        const self = this;

        this.$element.on('click', function (e) {
            e.preventDefault();
            $(this).addClass(self.classes.triggerDisabled);

            if (self.overlayState.isOpen) {
                self.closeOverlay();
            } else {
                self.openOverlay();
            }
        });

        $(this.overlayBackdrop).on('click', function (e) {
            e.preventDefault();
            self.closeOverlay();
        });

    }

    openOverlay() {
        const navItems = $().add(this.navItems);

        const openOverlayTimeline = new TimelineMax({
            onComplete: this.transitionEnded.bind(this),
            paused: true
        });

        $(this.overlay).removeClass(this.classes.hidden);

        openOverlayTimeline.fromTo(this.overlay, 0,
            {
                right: '-100%',
                autoRound: false,
                ease: Circ.easeIn
            },
            {
                right: 0
            }
        );

        openOverlayTimeline.fromTo(this.overlayBackground, .75,
            {
                right: '-100%',
                autoRound: false,
                ease: Circ.easeIn
            },
            {
                right: 0
            }
        );

        openOverlayTimeline.staggerFromTo(navItems, .1,
            {
                y: 20,
                opacity: 0,
                autoRound: false,
                ease: Power2.easeInOut
            },
            {
                y: 0,
                delay: .5,
                opacity: 1
            },
            .02,
            "-=.55"
        );

        openOverlayTimeline.play();
        this.updateClasses('opening');
    }

    closeOverlay() {
        const navItems = $().add(this.navItems);
        const closeOverlayTimeline = new TimelineMax({
            onComplete: this.transitionEnded.bind(this),
            paused: true
        });

        closeOverlayTimeline.fromTo(navItems, .15,
            {
                y: 0,
                opacity: 1,
                autoRound: false,
                ease: Power2.easeInOut
            },
            {
                y: 20,
                opacity: 0
            },
            .05
        );

        closeOverlayTimeline.fromTo(this.overlayBackground, .5,
            {
                right: 0,
                autoRound: false,
                ease: Circ.easeIn
            },
            {
                delay: .5,
                right: '-100%'
            }
        );

        closeOverlayTimeline.fromTo(this.overlay, 0,
            {
                right: 0,
                autoRound: false,
                ease: Circ.easeIn
            },
            {
                right: '-100%'
            }
        );

        closeOverlayTimeline.play();
        this.updateClasses('closing');
    }

    transitionEnded() {
        if (!this.overlayState.isOpen) {
            $(this.overlay).addClass(this.classes.hidden);
        }
        this.$element.removeClass(this.classes.triggerDisabled);
    }

    updateClasses(action) {
        if (action === 'opening') {
            $('body').addClass(this.classes.overlayOpened);
            this.$element.addClass(this.classes.triggerActive);
            this.$element.find(this.selectors.icon).addClass(this.classes.triggerActive);
            this.overlayState.isOpen = true;
            this.disableBodyScroll(this.overlay);
        } else {
            $('body').removeClass(this.classes.overlayOpened);
            this.$element.removeClass(this.classes.triggerActive);
            this.$element.find(this.selectors.icon).removeClass(this.classes.triggerActive);
            this.overlayState.isOpen = false;
            this.enableBodyScroll(this.overlay);
        }
    }
}
