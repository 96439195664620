// jQuery
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// Bootstrap
require('bootstrap');

require('./_jquery-extended');
require('./_config');
require('./_utils').init();
