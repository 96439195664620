import $ from 'jquery';
import parallax from 'jquery-parallax.js';

const defaults = {
    $element: undefined,
    selectors: {
        parallaxMirror: '.parallax-mirror',
        parallaxSlider: '.parallax-slider',
    }
};

export default class Parallax {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.options = members.options;
    }

    init() {
        const self = this;

        window.addEventListener('load', function () {
            const imageSrc = self.$element.data('image-src');
            const img = new Image();

            img.onload = function() {
                self.$element.parallax(self.options);

                const $mirror = $(self.selectors.parallaxSlider).filter(function()
                {
                    return ($(this).attr("src") === imageSrc)
                });
                const customClass = self.options.class;

                if (customClass) {
                    $mirror.closest(self.selectors.parallaxMirror).addClass(customClass);
                }
            }

            img.src = imageSrc;
            if (img.complete) img.onload();
        })
    }
}
