const events = {
    accordion: {
        // Toggle
        toggle: 'app::accordion::toggle',
        // Begin toggle
        beginToggle: 'app::accordion::beginToggle',
        // Expand
        expand: 'app::accordion::expand',
        // Collapse
        collapse: 'app::accordion::collapse'
    },
    // Dynamic content
    dynamicContent: {
        // Show
        show: 'app::dynamicContent::show',
        // Hide
        hide: 'app::dynamicContent::hide'
    }
};

export default events;
