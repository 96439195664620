import events from './_events';
import ScrollAnimations from "./_scroll-animations";
import Parallax from "./_parallax";
import Overlay from './_overlay';
import Header from './_header';
import ScrollToTarget from "./_scroll-to-target";
import NavigationOverlay from './_navigation-overlay';

// Utils
import utils from './_utils';

const app = (window._app = {
    _events: events,
    _scrollAnimations: ScrollAnimations,
    _parallax: Parallax,
    _overlay: Overlay,
    _header: Header,
    _scrollToTarget: ScrollToTarget,
    _navigationOverlay: NavigationOverlay,
    _utils: utils
});

export default app;
