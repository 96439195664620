import $ from 'jquery';

$(window).on('load', function() {
    if (location.hash) {
        window.scrollTo(0, 0);
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);
    }
})

function init(context) {
    $('[data-component]', context || 'body').addBack('[data-component]').each((moduleIndex, dataModule) => {
        const $module = $(dataModule);

        if ($module.data('module-instances') === undefined) {
            const data = $module.data();
            const modules = $module.data('component').split(',').map(module => module.trim());
            const instances = {};

            for (const key in modules) {
                const module = modules[key];

                if (window._app[module] !== undefined) {
                    const properties = Object.keys(data).reduce((carry, property) => {
                        if (typeof data[property] === 'string') {
                            carry[property] = data[property].match('=') ? queryToObject(data[property]) : carry[property];
                        } else {
                            carry[property] = data[property];
                        }

                        return carry;
                    }, data);

                    delete properties.js;

                    properties.$element = $module;

                    const instance = new window._app[module](properties);

                    instances[module] = instance;

                    properties.$element.data('module-instance', instance);
                    $module.data('module-instances', instances);

                    if (instance.init) {
                        instance.init();
                    }
                }
            }
        }
    });
}

function trigger(event, context, ...args) {
    $(context).trigger(event, ...args);
}

function scrollToTarget(target, offset) {
    const $target = $(document).find(target);

    $('html, body').animate({
        scrollTop: $target.offset().top - offset
    }, 500);
}

function getOptions(data) {
    let result = false;

    if (data) {
        result = JSON.parse(data);
    }

    return result;
}

module.exports = {
    init: init,
    trigger: trigger,
    scrollToTarget: scrollToTarget,
    getOptions: getOptions
};
