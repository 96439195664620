import $ from 'jquery';

const defaults = {
    $element: undefined,
    selectors: {
        search: '.js-header-search',
        searchInput: '.js-header-search-input',
        searchInputToggle: '.js-header-search-input-toggle'
    },
    classes: {
        toggleActive: 'active'
    },
    states: {
        searchActive: false
    }
};

export default class Header {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.states = members.states;
    }

    init() {
        const self = this;
        const $toggle = this.$element.find(this.selectors.searchInputToggle);
        const $form = $toggle.parents(self.selectors.search);

        $toggle.on('click', function (e){
            e.preventDefault();

            $(this).toggleClass(self.classes.toggleActive);

            if ($(this).hasClass(self.classes.toggleActive)) {
                $form.find(self.selectors.searchInput).focus();
                self.states.searchActive = true;
            } else {
                self.states.searchActive = false;
            }
        })

        $(document).click(function (e) {
            if (self.states.searchActive) {
                const $clicked = $(e.target);

                if (!$clicked.is($toggle) && !$clicked.parents().is(self.selectors.search)) {
                    $toggle.removeClass(self.classes.toggleActive);
                    self.states.searchActive = false;
                }
            }
        });

        $(document).keyup(function(e) {
            if (self.states.searchActive) {
                if (e.key === "Escape") {
                    $toggle.removeClass(self.classes.toggleActive);
                    self.states.searchActive = false;
                }
            }
        });
    }
}
